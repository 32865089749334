.container {
  flex-shrink: 0;
  border-radius: 8px;
  background: #ecf0f9;
  align-items: center;
  padding: 8px;
}

.uploadModal {
  justify-content: space-evenly;
  width: 100%;
  min-height: 15rem;
  align-items: center;
  padding: 8px;
}

.loader {
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 100%;
  height: 14rem;
  border-radius: 8px;
}

.title {
  width: 99%;
  /* height: 10%; */
  flex-shrink: 0;
  border-radius: 8px 8px 0px 0px;
  align-items: center;
  justify-content: space-between;
  /* padding-right: 18px; */
}

.titletext {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: #323232;
  line-height: 26px;
  font-family: Poppins;
}

.heading {
  width: 97.5%;
  height: 9%;
  border-radius: 8px;
  background: var(--white, #fff);
  border: 1px solid var(--grey-2, #e0e0e0);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 4px;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}

.body {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d8e1eb;
  background: var(--white, #fff);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  padding: 10px;
  gap:16px;
}

.button {
  min-width: 62px;
  width: 62px;
  height: 66px;
  background: var(--bt-blue, #18468f);
  border-radius: 6px;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}

.sendText {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;

}
.textContainer {
  width: 100%;
  gap: 6px;
}
.textWrapper {
  width: 70px;
  height: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--LIGHT-BLUE, #e8ecf4);
  background: var(--WHITE, #fff);
}
.textWrapper1{
  width: 70px;
  height: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--WHITE, #fff);
}
.text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: var(--black, #323232);
  text-align: left;
  font-family: Poppins;
}

.subject {
  width: 98%;
  flex-shrink: 0;
  border-bottom: 1px solid var(--light-grey, #dfdfdf);
  background: #fff;
  margin-top: 1.5%;
  align-self: center;
}

.subjectContainer {
  margin-top: 1%;
  margin-left: 15%;
  justify-content: flex-start;
  align-items: center;
}

.item {
  align-items: center;
  width: 15.5%;
  height: 80%;
  padding: 1% 8px;
  justify-content: space-between;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #ecf0f9;
  background: #fff;
  margin-left: 1.3%;
  position: relative;
  cursor: pointer;
  z-index: 2;
}

.itemStart {
  width: "80%";
  justify-content: flex-start;
  align-items: center;
}

.arrow {
  position: absolute;
  right: 15px;
  align-items: flex-end;
}

.itemText {
  text-align: center;
  font-size: 90%;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding-left: 4px;
}

.dropDown {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 89%;
}

.bodyTextWrapper {
  /* padding: 8px 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; */
}

.loading {
  margin: 2%;
}
