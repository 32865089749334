.selected-document {
  display: flex;
  width: 208px;
  padding: 6px 10px;
  align-items: center;
  gap: 21px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid var(--LIGHT-BLUE, #e8ecf4);
  background: #f3f5f9;
}
