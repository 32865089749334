.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.stack {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;

}

.pagination {
  display: flex;
  list-style: none;
  padding: 10px;
  justify-content: center;
  margin-top: 20px;

}

.pagination li {
  display: inline;
  margin-right: 15px;
}

.pagination a {
  text-decoration: none;
  cursor: pointer;
  font-size: 13px;
  padding: 6px 12px;
  color:#717070;
  font-weight: 600;
  font-family: Poppins;
  align-items: center;

}

.pagination a:hover {
  background: #f5f5f5;
 
}

.active a {

  padding:3px 10px;
  cursor: pointer;
 background: white; 
  color: #18468F;
  border-radius: 20px;
  border: 1px solid #18468F;
}


.sub-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.break-me {
  padding: 6px 12px;
}