.ButtonStack{
    width: 100%;
    align-content: center;
    margin-top: 1%;
    align-self: flex-end;
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
}

.Button{
    display: flex;
    height: 100px;
    padding-left: 35px;
    flex-direction: row;
}

@media (max-width: 750px ){
    .ButtonStack{
        width: 100%;
    }
    
}

@media (max-width:600px){
    .Button{
        flex-direction: column;
        padding-left: 0px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}