.dropdownCard {
  padding: 10px 14px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  background-color: white;
  cursor: pointer;
  min-width: 530px;
  justify-content: space-between;
}

.dropdownCard:hover{
  background-color: #e8ecf4;
}
.tick{
  display: none;
}

.dropdownCard:hover .tick{
  background-color: #e8ecf4;
  display: block;
}
.tick:hover{
  display: flex;
}

.documentName {
  width: 65%;
  align-items: center;
  gap: 8px;
  align-items: center;
}

.documentFamily {
  display: flex;
  padding: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  border: 1px solid var(--LIGHT-BLUE, #e8ecf4);
  background: var(--WHITE, #fff);
  color: var(--text-1, #323232);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
  text-align: center;
  margin-right: 14px;
}

.dateTime {
  color: var(--text-2, #717070);
  /* text-align: right; */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

.versionText {
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--text-1, #323232);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  border-radius: 3px;
  background: var(--BG, #f8f8f8);
}

.versionTextSelect {
  display: flex;
  padding: 4px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  border: 1px solid var(--LIGHT-BLUE, #e8ecf4);
  background: var(--WHITE, #fff);
  color: var(--text-1, #323232);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  align-self: center;
}
