mgt-person {
  --avatar-size: 22px;
  --font-size: 13px;
  --color: rgb(50, 49, 48);
  --font-weight: "normal";
  --font-family: "Poppins";
}

.ProjectStack {
  width: 100%;
  align-items: center;
  /* min-height: 19rem; */
  /* background-color: red; */
  height: 20rem;
  display: flex;
  flex-direction: row;
  margin-left: 5px;
}

.Projects {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  padding-bottom: 2px;
  gap:15px
}

.Card {
  height: 281px;
  width: 222px;
  min-width: 12rem;
  background-color: white;
  border: 2px solid #717070;
  position: relative;
  cursor: pointer;
  padding: 12px 7px;
}

.Icons {
  cursor: pointer;
  border-radius: 1rem;
  border: 2px solid #dfdfdf;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  position: absolute;
  z-index: 2;
}

.ProjectCardTop {
  height: 85%;
  position: relative;
}
.ProjectName {
  font-size: 16px;
  padding-top: 1%;
  font-weight: 500;
  line-height: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  color: #323232;
  padding-bottom: 1%;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 40px;
  max-height: 45px;
  font-family: Poppins;
}

.ProjectOwner {
  padding-top: 6%;
  padding-bottom: 4%;
}

.CounterParty {
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-bottom: 1%;
  justify-content: center;
  color: #323232;
  font-family: Poppins;
  font-weight: 400;
  font-style: normal;
}

.infinite-scroll-component__outerdiv{
  width:100%
}

@media (max-width: 800px) {
  .Card {
    width: 12vw;
    height: 16rem;
  }
  .ProjectStack {
    align-items: flex-start;
    height: 17.5rem;
  }
  .Projects {
    margin-left: 4%;
    margin-top: 1%;
  }
  .ProjectName {
    font-size: 20px;
  }
  .ProjectOwner {
    padding-top: 1%;
    padding-bottom: 2%;
  }
}

@media (max-width: 600px) {
  .ProjectStack {
    flex-direction: column;
    height: fit-content;
    width: 100vw;
    align-items: center;
  }
  .Projects {
    flex-direction: column;
    height: fit-content;
    width: 100vw;
    margin-left: 0%;
  }
  .Card {
    width: 85vw;
    height: 17rem;
    padding-left: 0%;
    margin-left: 0%;
  }
  .Icons {
    display: none;
  }
  .ProjectOwner {
    padding-top: 0%;
    padding-bottom: 0%;
  }
  .ProjectCardTop {
  }
}
